/* Add this to your style.scss or a CSS file */
@keyframes fillIcon {
    from {
        fill: transparent;
    }

    to {
        fill: currentColor;
    }
}

@keyframes fillLine {
    from {
        background-color: transparent;
    }

    to {
        background-color: currentColor;
    }
}

.timeline-dot-filled {
    animation: fillIcon 1s forwards;
}

.timeline-connector-filled {
    animation: fillLine 1s forwards;
}

.imgFLuid {
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
}