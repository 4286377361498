.hotels {
    padding-top: 2rem;
    padding-left: 1px;
    padding-right: 1px;

    @media screen and (min-width: 1200px) {
        padding-bottom: 2rem;
    }

    &.swiper-wrapper {
        padding-left: 3px;
    }
}