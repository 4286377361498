
.menu {
    a {
        text-decoration: none;
        width: 100%;
        padding: 11px 16px;
        color: #231F20;

        &:hover {
            background-color: #231F20;
            color: #fff;
            text-decoration: none;
        }
    }
}


.mobile {
    &LockHeight {
        height: 100%;
        border-radius: 8px;
        width: 100%;
    }
}