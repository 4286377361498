.pdpSwiper {
    .swiper-pagination-bullet {
        outline: 1px solid #fff;
    }
}

.travelDetailsCarouselHeight {
    @media screen and (min-width: 900px) {
        height: 500px;
    }

    @media screen and (min-width: 1200) {
        height: auto;
    }
}