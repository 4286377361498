:root {
    --primary: #0ABAB5;
    --tertiary: #231F20;
    --grayLight: #F3F6F5;
    --white: #fff;
    --clear: transparent;
}

.minHeight {
    min-height: 60vh;
}

.position-relative {
    position: relative;
}

.aaryan-col-3__no-border {
    &::after {
        display: none;
    }
}