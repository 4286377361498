.imgFluid {
    width: 100%;
    max-width: 100%;
}

.borderRadius8 {
    border-radius: 8px;
}

.photoArrayMantle {
    display: grid;
    grid-gap: .5em;
    grid-template-areas:
        "pcOne pcTwo pcThree"
        "pcOne pcFour pcFive";
    grid-template-columns: 1fr 20% 20%;

    div {
        height: 246px;

        img {
            height: inherit;
        }
    }


    &>div:first-child {
        grid-area: pcOne;
        height: 500px;

        img {
            height: inherit;
        }
    }
}

.photoArrayGallery {
    display: grid;
    grid-gap: .5em;
    grid-template-areas:
        "pcOne pcTwo"
        "pcOne pcThree";
    grid-template-columns: 1fr 30%;

    div {
        height: 103px;

        img {
            height: inherit;
        }
    }

    &>div:first-child {
        grid-area: pcOne;
        height: 210px;

        img {
            height: inherit;
        }
    }
}