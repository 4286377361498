:root {
    --primary: #0ABAB5;
    --tertiary: #231F20;
    --grayLight: #F3F6F5;
    --white: #fff;
    --baje:  #FFF7F0;
    --clear: transparent;
}

.bgGray {
    background-color: var(--grayLight);
}

/* 
.borderRadius {
    border-radius: 8px;
}
 */

ul.aaryan-menu {
    transition: all .5s ease-in;
}

ul.aaryan-menu>li.pr-0 {
    padding-right: 0;
}

.aaryan-menu-mega-blog-parent>a {
    line-height: 60px !important;
    transition: all .5s ease-in;
}

.aaryan-menu-mega-blog-parent>a:hover {
    background-color: var(--baje);
    color: var(--black) !important;

}

div.aaryan-wrapper {
    position: relative;
    width: 100%;
    /* height: 50px;
    margin: 0 auto; */
}

div.aaryan-wrapper ul {
    list-style: none
}

div.aaryan-wrapper>ul {
    position: relative
}

ul.aaryan-menu>li {
    float: left;
    /* font-size: 14px */
    /* padding-right: 16px; */
}

ul.aaryan-menu>li.aaryan-menu-right {
    float: right;
    padding-right: 0;
}

ul.aaryan-menu>li>a {
    display: block;
    line-height: 50px;
    padding: 0 25px 0 15px;
    color: #efefef;
    text-decoration: none;
    font-weight: 400;
    /* text-transform: uppercase */
}

ul.aaryan-menu>li>a.scrolled {
    color: var(--tertiary);
}

ul.aaryan-menu>li.active .scrolled {
    color: var(--white);
}

.pr-0 {
    padding-right: 0;
}

/* 
ul.aaryan-menu>li>a:only-child {
    padding: 0 15px
} */

ul.aaryan-menu>li>a:only-child:after,
ul.aaryan-menu>li:hover>a:only-child:after {
    content: ''
}

ul.aaryan-menu>li.aaryan-active-menu-item>a {
    background: #17a47d
}

ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a,
ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-menu-social.aaryan-menu-search>a {
    max-height: 50px;
    width: 30px;
    transition: all .5s ease;
    transition-delay: .3s
}

ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a:hover,
ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-menu-social.aaryan-menu-search>a:hover {
    width: 120px
}

ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a:hover>span input,
ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-menu-social.aaryan-menu-search>a:hover>span input {
    transform: scaleY(1);
    max-width: 80px
}

ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a span,
ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-menu-social.aaryan-menu-search>a span {
    display: inline
}

ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a>span input,
ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-menu-social.aaryan-menu-search>a>span input {
    position: absolute;
    width: 80px;
    height: 50px;
    line-height: 50px;
    margin: 0;
    background: none;
    color: #efefef;
    border: none;

    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 0;
    transform: scaleY(0);
    transition: all .5s ease;
    transition-delay: .3s
}

ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a>span input:focus {
    outline: none;
    color: #efefef
}

ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a>span input::-webkit-input-placeholder {
    color: #efefef
}

ul.aaryan-menu.aaryan-menu-dividers>li>a,
ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-menu-social>a {
    border-left: 1px solid #111;
    border-right: 1px solid #666
}

ul.aaryan-menu.aaryan-menu-dividers>li:first-child>a,
ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-menu-social:last-child>a {
    border-left: none
}

ul.aaryan-menu.aaryan-vertical.aaryan-menu-dividers>li>a {
    border: none;
    border-top: 1px solid #111;
    border-bottom: 1px solid #666
}

ul.aaryan-menu.aaryan-vertical.aaryan-menu-dividers>li:first-child>a {
    border-top: none
}

ul.aaryan-menu.aaryan-menu-dividers>li>a:hover,
ul.aaryan-menu.aaryan-menu-dividers>li:hover>a {
    border-right: 1px solid var(--primary)
}

ul.aaryan-menu.aaryan-vertical.aaryan-menu-dividers>li>a:hover,
ul.aaryan-menu.aaryan-vertical.aaryan-menu-dividers>li:hover>a {
    border-bottom: 1px solid var(--primary)
}

ul.aaryan-menu.aaryan-menu-dividers>li.aaryan-active-menu-item>a {
    border-right: 1px solid #17a47d
}

ul.aaryan-menu.aaryan-vertical.aaryan-menu-dividers>li.aaryan-active-menu-item>a {
    border-bottom: 1px solid #17a47d
}

ul.aaryan-menu>li>ul,
ul.aaryan-menu>li>ul>li>ul,
ul.aaryan-menu>li>ul>li>ul>li>ul {
    position: absolute;
    box-shadow: 0 8px 24px -5px #ccc;
    border-top: 4px solid var(--primary)
}

ul.aaryan-menu>li>ul>li,
ul.aaryan-menu>li>ul>li>ul>li {
    position: relative
}

ul.aaryan-menu>li>ul>li>a,
ul.aaryan-menu>li>ul>li>ul>li>a,
ul.aaryan-menu>li>ul>li>ul>li>ul>li>a {
    display: block;
    width: 170px;
    line-height: 40px;
    padding: 0 35px 0 25px;
    background: #fff;
    color: #666;
    text-decoration: none;
    font-size: 14px
}

ul.aaryan-menu>li>ul>li:hover>a,
ul.aaryan-menu>li>ul>li>a:hover,
ul.aaryan-menu>li>ul>li>ul>li:hover>a,
ul.aaryan-menu>li>ul>li>ul>li>a:hover,
ul.aaryan-menu>li>ul>li>ul>li>ul>li:hover>a,
ul.aaryan-menu>li>ul>li>ul>li>ul>li>a:hover {
    background: #efefef
}

ul.aaryan-menu>li>ul>li>a:only-child:after,
ul.aaryan-menu>li>ul>li>ul>li>a:only-child:after,
ul.aaryan-menu>li>ul>li>ul>li>ul>li>a:only-child:after {
    content: ""
}

ul.aaryan-menu>li>ul>li>a .fa,
ul.aaryan-menu>li>ul>li>ul>li>a .fa,
ul.aaryan-menu>li>ul>li>ul>li>ul>li>a .fa {
    position: relative;
    width: 24px
}

ul.aaryan-menu>li.aaryan-menu-right>ul {
    right: 0
}

ul.aaryan-menu>li>ul>li>ul,
ul.aaryan-menu>li>ul>li>ul>li>ul {
    left: 170px;
    top: -4px;
    z-index: 100
}

ul.aaryan-menu>li>ul>li.aaryan-open-to-left>ul,
ul.aaryan-menu>li>ul>li>ul>li.aaryan-open-to-left>ul {
    left: auto;
    right: 170px
}

ul.aaryan-menu>li.aaryan-menu-mega>div,
ul.aaryan-menu>li.aaryan-menu-mega-blog>div {
    position: absolute;
    width: 100%;
    height: auto;
    top: 60px;
    left: 0;
    background: var(--white);
    /* overflow: hidden; */
}

ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav {
    position: relative;
    width: 200px;
    height: 100%;
    left: 0;
    top: 0;
    background: #efefef
}

ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li {
    font-size: 14px
}

ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li>a {
    display: block;
    line-height: 40px;
    padding: 0 15px;
    background: var(--white);
    color: var(--tertiary);
    text-decoration: none;
    transition: all .5s ease-in;
}

ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li.aaryan-active-menu-item>a {
    background: var(--grayLight);
    color: var(--tertiary);
}
/* ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li.aaryan-menu-item>a {
    background: var(--grayLight);
    color: var(--tertiary);
} */
ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li>div {
    position: absolute;
    width: 780px;
    height: 100%;
    /* min-height: 100%; */
    overflow-y: auto;
    left: 200px;
    top: 0;

    /* background: #fff */
}

ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li:hover>a {
    background: var(--baje);
    color: var(--black);
}


div.aaryan-grid,
div.aaryan-grid-lined {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0
}

div.aaryan-grid>div.aaryan-row {
    position: relative;
    width: 100%;
    height: auto
}

div.aaryan-grid>div.aaryan-row div.aaryan-row [class^="aaryan-col"] {
    margin-top: 16px
}

div.aaryan-grid>div.aaryan-row div.aaryan-row:first-of-type [class^="aaryan-col"] {
    margin-top: 0
}

div.aaryan-grid.aaryan-grid-lined>div.aaryan-row:after {
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(left, rgba(204, 204, 204, 0) 0%, rgba(204, 204, 204, 0.48) 24%, rgba(204, 204, 204, 1) 50%, rgba(204, 204, 204, 0.42) 79%, rgba(204, 204, 204, 0) 100%)
}

div.aaryan-grid.aaryan-grid-lined>div.aaryan-row:last-child:after {
    height: 0
}

div.aaryan-grid>div.aaryan-row::after {
    content: "";
    display: table;
    clear: both
}

div.aaryan-grid>div.aaryan-row [class^="aaryan-col"] {
    position: relative;
    float: left;
    height: auto
}

div.aaryan-grid>div.aaryan-row>[class^="aaryan-col"] {
    padding: 16px
}

div.aaryan-grid>div.aaryan-row>[class^="aaryan-col"] [class^="aaryan-col"] {
    padding-left: 16px
}

div.aaryan-grid>div.aaryan-row>[class^="aaryan-col"] [class^="aaryan-col"]:first-of-type {
    padding-left: 0
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-1 {
    width: 8.33%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-2 {
    width: 16.66%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-3 {
    width: 25%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-4 {
    width: 33.33%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-5 {
    width: 41.66%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-6 {
    width: 50%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-7 {
    width: 58.33%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-8 {
    width: 66.66%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-9 {
    width: 75%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-10 {
    width: 83.33%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-11 {
    width: 91.66%
}

div.aaryan-grid>div.aaryan-row div.aaryan-col-12 {
    width: 100%
}

div.aaryan-grid.aaryan-grid-lined>div.aaryan-row>div[class^="aaryan-col"]:after {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    right: 0;
    background: -webkit-linear-gradient(top, rgba(204, 204, 204, 0) 0%, rgba(204, 204, 204, 0.48) 24%, rgba(204, 204, 204, 1) 50%, rgba(204, 204, 204, 0.42) 79%, rgba(204, 204, 204, 0) 100%)
}

div.aaryan-grid.aaryan-grid-lined>div.aaryan-row>div[class^="aaryan-col"]:last-child:after {
    width: 0
}

div.aaryan-grid img,
div.aaryan-grid video {
    position: relative;
    max-width: 100%;
    height: 110px;
    object-fit: cover;
    width: 100%;
    display: block;
}

h3.aaryan-list-heading {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    margin: 0
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul {
    margin: 0;
    margin-top: 10px
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul li {
    position: relative;
    font-size: 14px;
    line-height: 28px;
    display: block;
    width: 100%
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul li>a {
    text-decoration: none;
    color: #555;
    display: inline-block
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul li>a:hover {
    color: #222;
    text-decoration: underline
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul li .fa {
    position: relative;
    width: 24px
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul.aaryan-list-with-images li {
    height: 50px
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul.aaryan-list-with-images li span.aaryan-list-desc {
    font-size: 11px;
    display: block;
    position: absolute;
    top: 16px;
    left: 72px;
    color: #999
}

ul.aaryan-menu>li.aaryan-menu-mega>div ul li>a>img {
    float: left;
    width: 60px;
    margin-right: 12px
}

span.aaryan-c-title {
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, .15);
    color: #fff;
    font-weight: 500;
    margin-top: 0;
    align-items: center;
    border-radius: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
}

span.aaryan-c-content {
    display: block;
    position: relative;
    top: 7px;
    font-size: 12px;
    line-height: 18px;
    text-align: justify
}

a.hover {
    transition: all .3s ease-in;
}

.aaryan-c-title {
    transition: background-color 0.3s ease-in-out;
    /* Applies smooth transition in and out */
    background-color: transparent;
    /* Initial state without background color */
}

.hover:hover .aaryan-c-title {
    background-color: rgba(0, 0, 0, 0.47);
    transition-delay: 0.3s;
}

.aaryan-wrapper .visible-xs {
    visibility: hidden;
    position: absolute
}

@media(min-width: 767px) {
    div.aaryan-wrapper.aaryan-menu-transparent {
        background: none
    }

    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu>li.aaryan-active-menu-item>a {
        background: none
    }

    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu>li.aaryan-menu-social>a {
        border: none
    }

    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu.aaryan-menu-dividers>li>a {
        border: none
    }

    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu>li:hover>a,
    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu>li>a:hover,
    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu.aaryan-menu-dividers>li:hover>a,
    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu.aaryan-menu-dividers>li>a:hover {
        background: none;
        color: #e2e2e2
    }

    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search>a:hover,
    div.aaryan-wrapper.aaryan-menu-transparent>ul.aaryan-menu>li.aaryan-menu-social.aaryan-menu-search:hover>a {
        background: none
    }

    div.aaryan-wrapper.aaryan-menu-full-width {
        width: 100%
    }

    div.aaryan-wrapper.aaryan-menu-full-width>ul.aaryan-menu {
        width: 980px;
        margin: 0 auto
    }

    ul.aaryan-menu>li>ul,
    ul.aaryan-menu>li>ul>li>ul,
    ul.aaryan-menu>li>ul>li>ul>li>ul {
        visibility: hidden;
        opacity: 0
    }

    ul.aaryan-menu>li:hover>ul,
    ul.aaryan-menu>li>ul>li:hover>ul,
    ul.aaryan-menu>li>ul>li>ul>li:hover>ul {
        visibility: visible;
        opacity: 1
    }

    ul.aaryan-menu>li.aaryan-menu-mega>div,
    ul.aaryan-menu>li.aaryan-menu-mega-blog>div {
        visibility: hidden;
        opacity: 0
    }

    ul.aaryan-menu>li.aaryan-menu-mega:hover>div,
    ul.aaryan-menu>li.aaryan-menu-mega-blog:hover>div {
        visibility: visible;
        opacity: 1
    }

    ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li>div {
        visibility: hidden;
        opacity: 0
    }

    ul.aaryan-menu>li.aaryan-menu-mega-blog:hover>div>ul.aaryan-menu-mega-blog-nav>li.aaryan-active-menu-item>div {
        visibility: visible;
        opacity: 1
    }

    ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li:hover>div {
        visibility: visible;
        opacity: 1;
        z-index: 101
    }

    ul.aaryan-menu>li>a:after {
        text-decoration: none;
        content: '\e313';
        font-family: 'Material Symbols Outlined';
        position: absolute;
        margin-left: 5px
    }

    ul.aaryan-menu>li:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg)
    }

    ul.aaryan-menu>li>ul>li>a:after,
    ul.aaryan-menu>li>ul>li>ul>li>a:after,
    ul.aaryan-menu>li>ul>li>ul>li>ul>li>a:after {
        font-family: "FontAwesome";
        text-decoration: none;
        font-size: 16px;
        padding: 0;
        content: '\f105';
        position: absolute;
        right: 15px
    }

    ul.aaryan-menu>li>ul>li:hover>a:after,
    ul.aaryan-menu>li>ul>li>ul>li:hover>a:after,
    ul.aaryan-menu>li>ul>li>ul>li>ul>li:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg)
    }

    ul.aaryan-menu>li>ul>li.aaryan-open-to-left>a:after,
    ul.aaryan-menu>li>ul>li>ul>li.aaryan-open-to-left>a:after,
    ul.aaryan-menu>li>ul>li>ul>li>ul>li.aaryan-open-to-left>a:after {
        content: '\f104';
        right: auto;
        left: 10px
    }

    ul.aaryan-menu>li>ul>li.aaryan-open-to-left:hover>a:after,
    ul.aaryan-menu>li>ul>li>ul>li.aaryan-open-to-left:hover>a:after,
    ul.aaryan-menu>li>ul>li>ul>li>ul>li.aaryan-open-to-left:hover>a:after {
        transform: rotate(-180deg)
    }

    ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li.aaryan-active-menu-item>a:after,
    ul.aaryan-menu>li.aaryan-menu-mega-blog>div>ul.aaryan-menu-mega-blog-nav>li:hover>a:after {
        content: '\e5e1';
        font-family: 'Material Symbols Outlined';
        position: absolute;
        right: 10px;

    }


}

.active {
    background-color: var(--primary);
}

ul.aaryan-menu>li.active>a {
    color: var(--white);
}