.logo {
    display: flex !important;
    width: 100%;
    max-width: 54px;

    @media screen and (min-width: 1200px) {
        max-width: 64px;
    }
}

.pNone {
    padding: 0 !important;
}