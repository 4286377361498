/* Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.5s ease;
    overflow: hidden;
}

.preloader.fade-out {
    opacity: 0;
    pointer-events: none;
}
